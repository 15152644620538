import React, { useRef, useState, useEffect } from 'react';
import styles from './Header.module.scss';
import { Context } from '../Store';
import useMediaQuery from "../hooks/useMediaQuery";

const Header = (props) => {

  const state = React.useContext(Context); 
  const [currentIndex, setCurrentIndex] = useState(0);

  const isDesktop = useMediaQuery('(min-width: 768px)');
  let n = isDesktop ? 2 : 1;
  let l = state.photos.length;

  let photos = [];
  let leftPhotos = [];
  let rightPhotos = [];
  
  state.photos.map((team, index) => {
    let teamColour = (['pink', 'blue'].includes(team.name.toLowerCase())) ? team.name.toLowerCase() : (team.colour == 1 ) ? 'blue' : 'pink';
    let teamPhoto =  'data:image/jpeg;base64,' + team.Photo;
    if(l === 2){
      photos.push(
        <div key={team.id} className={styles.teamPhotoContainer}>
          <div className={`team_${teamColour} ${styles.borderHolder}`} >
            <img key={team.id} src={teamPhoto} alt={ team.name + ' Team'} />
          </div>
        </div>
      )
    }
    else{
      let c = currentIndex
      let c1 = (c + (n-1) >= l) ? (c+(n-1)) - l : c +(n-1);
      let c2 = (c + n >= l) ? (c+n) - l : c +n;
      let c3 = (c + (n+1) >= l) ? (c+(n+1)) - l : c +(n+1);
      if( index == c || index == c2){
        if(index == c)leftPhotos.push(
            <div key={team.id} className={`team_${teamColour} ${styles.borderHolder}`} >
              <img key={team.id} className={`team_${teamColour}`} src={teamPhoto} alt={ team.name + ' Team'} />
            </div>
          );
        if(index == c2)leftPhotos.unshift(
          <div key={team.id} className={`team_${teamColour} ${styles.borderHolder}`} >
            <img key={team.id} className={`team_${teamColour}`} src={teamPhoto} alt={ team.name + ' Team'} />
          </div>
        );
      }
      if(index == c1 || index == c3){
        if(index == c1)rightPhotos.push(
          <div key={team.id} className={`team_${teamColour} ${styles.borderHolder}`} >
            <img key={team.id} className={`team_${teamColour}`} src={teamPhoto} alt={ team.name + ' Team'} />
          </div>
        )
        if(index == c3)rightPhotos.unshift(
          <div key={team.id} className={`team_${teamColour} ${styles.borderHolder}`} >
            <img key={team.id} className={`team_${teamColour}`} src={teamPhoto} alt={ team.name + ' Team'} />
          </div>
        )
      }
    }
  })

  useEffect(() => {
    if(l > 2){
      const intervalId = setInterval(() => {
        let nextIndex = (currentIndex+n >= l) ? ((currentIndex+n) - l) + 0 : currentIndex+n;
        setCurrentIndex(nextIndex);
      }, 6000)

      return () => clearInterval(intervalId);
    }
}, [currentIndex])


  let venuePhoto = () => {
    return (
      <div className={styles.venuePhotoContainer}>
        <img src={'./img/es-stories_venue-header_' + state.venue.toLowerCase() + '.jpg'} alt={ state.venue  + ' Photo'} />
      </div>
    )
  } 




  let TeamPhotos = () => {
    if(l < 2){
      return venuePhoto();
    }else if(l === 2){
      return photos;
    }else{
      return (
        <>
          <div className={[styles.teamPhotoContainer, styles.left].join(' ')}>{leftPhotos}</div>
          <div className={[styles.teamPhotoContainer, styles.right].join(' ')}>{rightPhotos}</div>
        </>
      )
    }
  }

  return (
      <>
        { state.feedbackurl !== '' &&
          <div className={styles.feedback}>
            <div>
            We'd love to hear your feedback. <a href={state.feedbackurl} target="_blank" >Enter&nbsp;here</a>&nbsp;for&nbsp;a&nbsp;chance&nbsp;to&nbsp;win&nbsp;£250!
            </div>
          </div> 
        }
        <div className={styles.header}>

          <div className={styles.photo}>
            <TeamPhotos/>
          </div>
          
          <div className={styles.logoContainer}>
            <div className={styles.logoShadow}><img src="./img/logo_shadow.png" alt="" /></div>
            <div className={styles.logo}><img src="./img/es_logo-full.png" alt="Stories" /></div>
            {/* <div className={styles.logo}><img src="./img/es_logo.png" alt="Stories" /></div>
            <div className={styles.logo}><img src="./img/es_stories_logo.png" alt="Stories" /></div> */}
          </div>
        </div>
      </>
    );

}

export default Header;