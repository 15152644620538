// Base container for the app. 
// data Pulled from Azure, sorted as required and passed to each component
// Component are then built into the app if there is data associated.
import React, { useContext, useState } from 'react';
import { Context } from '../Store';
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import Header from '../components/Header';
import Players from '../components/Players';
import ActionReplays from '../components/ActionReplays';
import GroupPhotos from '../components/GroupPhotos';
import Games from '../components/Games';
import Loopcams from '../components/Loopcams';
import CopyLinkContainer from '../components/CopyLinkContainer/CopyLinkContainer';
import LoadingStory from '../components/LoadingStory';

const StoryContainer = () => {
  // Setting app context to Store.js Where the overall state is kept
  const [state, setState] = useContext(Context);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const debug = params.get('debug');
  // set up azure Var
  let StoriesAzureAPI;
  let country = window.location.href.indexOf("usa") != -1 ? 'us' : 'uk';

  // Set Azzure connection
  if (country == 'us'){
    StoriesAzureAPI = axios.create({
      baseURL: "https://usesstoryfunctions-apim.azure-api.net/StoryFunctions/StoryData/",
      headers: {
        "Content-type": "application/json",
        "ES-Key": "3818a8a2e8514f188a05e428e3e04dc4"
      }
    });
  }else{
    StoriesAzureAPI = axios.create({
      baseURL: "https://es-stories-apim.azure-api.net/Stories/StoryData/",
      headers: {
        "Content-type": "application/json",
        "ES-Key": "b183b053fcfb429ca0dddc355c2cfb9a"
      }
    });
  }

  // On load will use the string after the first slash (/) as the GUID
  // to request Stories data from the Azure API. Will work both with or without the trailing #/ in the URL
  let { guid } = useParams();
  const actionReplayEventTypes = [5, 50, 51, 100, 155, 200, 201, 202];
  const gameEventsTypes = [1,2]

  // Set up all Venues with appropriate variables including Disploay name, city, website URL and blob url
  const venues = [
    { code: 'HQ', name: 'HQ', city:'london', url: 'electricshuffle.com/london', azure_url: 'https://esstories-ukso1.streaming.media.azure.net/', blob_url:"https://esstoriesvideostorage.blob.core.windows.net/",  feedback_url: '' },
    { code: 'CANAR', name: 'CanaryWharf', city:'london', url: 'electricshuffle.com/london', azure_url: 'https://esstories-ukso1.streaming.media.azure.net/', blob_url:"https://esstoriesvideostorage.blob.core.windows.net/",  feedback_url: 'https://bit.ly/422KHdZ' },
    { code: 'LDNBR', name: 'LondonBridge', city:'london', url: 'electricshuffle.com/london', azure_url: 'https://esstories-ukso1.streaming.media.azure.net/', blob_url:"https://esstoriesvideostorage.blob.core.windows.net/",  feedback_url: 'https://bit.ly/3J8ffm5' },
    { code: 'LEEDS', name: 'Leeds', city:'leeds', url: 'electricshuffle.com/leeds', azure_url: 'https://esstories-ukso1.streaming.media.azure.net/', blob_url:"https://esstoriesvideostorage.blob.core.windows.net/",  feedback_url: 'https://bit.ly/3ZZ0Orw' },
    { code: 'MANCH', name: 'Manchester', city:'manchester', url: 'electricshuffle.com/manchester', azure_url: 'https://esstories-ukso1.streaming.media.azure.net/', blob_url:"https://esstoriesvideostorage.blob.core.windows.net/",  feedback_url: '' },
    { code: 'AUSTI', name: 'Austin', city:'austin', url: 'electricshuffleusa.com/austin', azure_url: 'https://esusastoriesmedia-usw22.streaming.media.azure.net/', blob_url:"https://esusastoriesvideos.blob.core.windows.net/",  feedback_url: '' },
    { code: 'DALLA', name: 'Dallas', city:'dallas', url: 'electricshuffleusa.com/dallas', azure_url: 'https://esusastoriesmedia-usw22.streaming.media.azure.net/', blob_url:"https://esusastoriesvideos.blob.core.windows.net/",  feedback_url: '' },
    { code: 'BROAD', name: 'NYC', city:'nyc', url: 'electricshuffle.com/us/nyc', azure_url: 'https://esusastoriesmedia-usw22.streaming.media.azure.net/', blob_url:"https://esusastoriesvideos.blob.core.windows.net/",  feedback_url: '' }
  ];
  // function to get Venue array based on story data venue code
  const getVenueName = (venue_code) => {
      let venue = {};
      venues.forEach((item) => {
        if (venue_code.toUpperCase() == item.code){
          venue = {
            venue_name: item.name,
            city_name: item.city,
            city_url: item.url,
            azure_url: item.azure_url,
            blob_url: item.blob_url,
            feedback_url: item.feedback_url
          }
        }
      });
      return venue;
  }

  // Get story data from GUID 
  const getStoryData = async (id) => {
    // Get story data depending on url (USA is using a slightly differnt system which requires a different call)
    let story = 
      await StoriesAzureAPI.get(id).then(
        response => {
          if(debug)console.log('API request response:', response);
          return response;
        }
      );
      
    // After raw Story data has been pulled we trigger getVenueName
    let venue = getVenueName(story.data.venue_code);
    let player1 = story.data.Players[0].id;

    // Get all events from story and sort them into different arrays for photos, loopcams, actionreplays and games
    let events = story.data.Newsfeed.reduce((data, event) => {
      if (gameEventsTypes.includes(event.type) || actionReplayEventTypes.includes(event.type)) {
        // Event between 50 - 250 - Action Replay. if Uri exits add it to array
        if (event.video_url !== '' && event.video_url !== null ){
          data.actionReplays.push(event);
        }
        if(event.type == 1){
          if(!(event.game in data.games)){ 
            data.games[event.game] = []; 
          }
          event.players = player1;
        }
        if(event.game != 'TBC' && event.game != null && (event.game in data.games)){
            data.games[event.game].push(event);    
        }

        // }else if(event.game != 'TBC'){
        //     data.games[event.game].push(event);    
        // }
      }
      return data;
    }, {
      actionReplays: [],
      games: {}
    });
    //set up array for game events
    let gameEvents = [];
    //loop through each game type from games object
    for (let [key, game] of Object.entries(events.games)){
      // set highscor var to black at the start of each loop
      let highscore;
      let obj;
      // switch to do different array manipulation dependant on game type
      switch(key) {
        case 'Amplifier':
          highscore = Math.max.apply(null, game.map(function(o){
            if(o.type == 2){
              return +JSON.parse(o.round_scores).slice(0, -2).sort((a, b)=>{return b - a})[0];
            }else{
              return 0
            }
          }))
          obj = game.find((o)=>{if(o.type == 2){return JSON.parse(o.round_scores).includes(highscore)}})
          if(typeof obj != "undefined" && obj.players){
            gameEvents.push(obj)
          }
          break;
        case 'Eclipse':
          highscore = Math.min.apply(null, game.map(function(o){
            if(o.type == 2){
              return +JSON.parse(o.round_scores).slice(0, -2).sort((a, b)=>{return a - b})[0];
            }else{
              return 9999
            }
          }))
          obj = game.find((o)=>{if(o.type == 2){return JSON.parse(o.round_scores).includes(highscore)}})
          if(typeof obj != "undefined" && obj.players){
            gameEvents.push(obj)
          }
          break
        case 'ShortCircuit':
          highscore = Math.min.apply(null, game.map(function(o){
            if(o.type == 2){
              return +JSON.parse(o.round_scores).slice(0, -2).sort((a, b)=>{return a - b})[0];
            }else{
              return 9999
            }
          }))
          obj = game.find((o)=>{if(o.type == 2){return JSON.parse(o.round_scores).includes(highscore)}})
          if(typeof obj != "undefined" && obj.players){
            gameEvents.push(obj)
          }
          break
        case 'Territory':
          highscore = Math.max.apply(null, game.map(function(o){
            if(o.type == 2){
              return +JSON.parse(o.round_scores).slice(0, -2).sort((a, b)=>{return b - a})[0];
            }else{
              return 0
            }
          }))
          obj = game.find((o)=>{if(o.type == 2){return JSON.parse(o.round_scores).includes(highscore)}})
          if(typeof obj != "undefined" && obj.players){
            gameEvents.push(obj)
          }
          break
        default :
          // default
        break;
      }
    }  
    let teamsPhotos = story.data.Teams.filter((e) => {
      if(e.Photo){
        return e;
      }
    })
    // Set state variables to story data and event data
    setState({
      ...state,
      guid: guid,
      type: story.data.type,
      reference: story.data.reference,
      venue: venue.venue_name,
      city: venue.city_name,
      country: country,
      cityurl: venue.city_url,
      azureurl: venue.azure_url,
      bloburl: venue.blob_url,
      feedbackurl: venue.feedback_url,
      players: story.data.Players,
      teams: story.data.Teams,
      newsfeed: story.data.Newsfeed,
      actionReplays:  (story.data.type == '1' || story.data.type == '3') ? events.actionReplays.slice((events.actionReplays.length -10 < 0 ? 0 : events.actionReplays.length -10), events.actionReplays.length).reverse() : events.actionReplays,
      photos: teamsPhotos,
      games: gameEvents,
      debug: debug
    });
    // GGoogle analytics trigger open story event
    ReactGA.event({
      category: "Story opened",
      action: 's: ' + window.location.host + ' v: ' + venue.venue_name
    });
  };

  // Check if state has been set. If not get data from guid and set display to "Loading"
  if (state.guid.length < 1) {
    getStoryData(guid);
    return (
      <div>
        <LoadingStory />
      </div>
    );
  }


  let setCurrentLightbox = null;
  const openLightbox = (lightboxDetails) => {
    if(lightboxDetails){
      setCurrentLightbox(lightboxDetails);
    }    
    setCurrentPreview('');
  }
  const updateLightbox = (dataFromChild) => {
    setCurrentLightbox = dataFromChild;
  }

  let hoverDelay;
  let setCurrentPreview = null;

  const handleHover = (previewDetails, type) => {
    clearTimeout(hoverDelay)
    if(type == 'mouseenter'){
      hoverDelay = setTimeout(()=>setCurrentPreview(previewDetails), 250);
    }
  }

  const updatePreview = (dataFromChild) => {
    setCurrentPreview = dataFromChild;
  }


  return (
    <div>
      <Context.Provider value={state}>
        <Header/>
        <Players openLightbox={openLightbox}/>
        { state.actionReplays.length >=1  &&
          <ActionReplays handleHover={handleHover}  openLightbox={openLightbox}/>
        }   
        { state.photos.length >=1  &&
          <GroupPhotos handleHover={handleHover} openLightbox={openLightbox}/>
        }
        { state.games.length >=1  &&
          <Games handleHover={handleHover} openLightbox={openLightbox}/>
        }
        {/* { state.loopcams.length >=1  &&
          <Loopcams handleHover={handleHover} openLightbox={openLightbox}/>
        } */}

        
        <CopyLinkContainer mount={updatePreview} callbackHandler={openLightbox} updateLightbox={updateLightbox}/>
       
      </Context.Provider>        
    </div>
  );
};

export default StoryContainer;