import useMediaQuery from "../hooks/useMediaQuery";
import React, { useState, useRef, useEffect  } from 'react';
import styles from './GroupPhotos.module.scss';
import TinySlider from "tiny-slider-react";
import SliderControls from "./SliderButton"
import sliderStyles from './TinySlider.module.scss';
// import FsLightbox from 'fslightbox-react';
//import { animOnSwipe } from '../scripts/animation';
import { getBoundingClientRect } from '../scripts/helpers';
import {isMobile} from 'react-device-detect';
import ReactGA from "react-ga4";
import { Context } from '../Store';


const GroupPhotos = (props) => {
  const state = React.useContext(Context); 

  const isDesktop = useMediaQuery('(min-width: 768px)');

  const sliderRef = useRef();

  const settings = {
    lazyload: true,
    touch: true,
    nav: false,
    controlsContainer: '.'+styles.sliderContainer + ' .sliderControls',
    mouseDrag: true,
    loop: true,
    items: 1,
    edgePadding: 0,
    responsive: {
      640:{
        items: (state.photos.length < 2) ? state.photos.length : 2 ,
        edgePadding: 30,
      },
      1024:{
        items: (state.photos.length < 3) ? state.photos.length : 3 ,
        edgePadding: 30,
      }
    }
  };

  let groupPhotoSources = state.photos.map(team => {
    return  'data:image/jpeg;base64,'  + team.Photo;
  }); 
  let groupPhotoMedia = state.photos.map(team => {
    return {
      mediaType: 'PHOTO',
      mediaShare: state.guid + '&team=' + team.id,
      mediaURI: 'data:image/jpeg;base64,'  + team.Photo,
      sectionName: 'Group Photo'
    };
  });

  
  // useEffect(() => {
  //   animOnSwipe(sliderRef.current.slider);
  // }, [sliderRef]);


  const handleHover = (index, target, type) => {
    let perviewDetails = {
      section: 'GroupPhoto',
      index: index,
      ...groupPhotoMedia[index],
      target: target,
      groupLength: groupPhotoMedia.length,
      sources: groupPhotoSources,
      mediashare: groupPhotoMedia,
      type:"image"
    };
    props.handleHover(perviewDetails, type)
  };

  const openLightbox = (index) => {
    let lightboxDetails = {
      section: 'GroupPhoto',
      index: index, 
      sources: groupPhotoSources,
      mediashare: groupPhotoMedia,
      type:"image"
    }
    props.openLightbox(lightboxDetails);
  }

  const sliderClickHandler = (e) => {
    let slide = e.target.closest(`.${styles.groupPhotoItem}`)
    if(slide){
      openLightbox(+slide.getAttribute('data-index'))
    }
  }
  let currentHover = null;
  const sliderHoverHandler = (e) => {
    let slide = e.target.closest(`.${styles.groupPhotoItem}`)
    if(slide && currentHover == null){
      currentHover = slide.getAttribute('data-index');
      handleHover(+currentHover, slide.querySelector('img'), 'mouseenter')
    }else if(!slide || e.type == 'mouseleave'){
      handleHover(+currentHover, slide, 'mouseleave')
      currentHover = null;
    }
  }


  return(
    <div className="section">
      <h2>PHOTO GALLERY</h2>
      
      { (isDesktop || state.photos.length > 2)  &&
        <div className={styles.sliderContainer} onClick={(e) => sliderClickHandler(e)} onMouseLeave={isMobile ? () => (false) : (e) => sliderHoverHandler(e)} onMouseOver={isMobile ? () => (false) : (e) => sliderHoverHandler(e)}>

          <SliderControls slider={sliderRef} ></SliderControls>

          <TinySlider settings={settings} ref={sliderRef}>
              {state.photos.map((team, index) => (
                <div key={index} data-index={index} style={{ position: "relative" }} className={['team_'+team.name.toLowerCase(), sliderStyles.slide, styles.groupPhotoItem].join(' ')} >
                    <img src={'data:image/jpeg;base64,'  + team.Photo} alt="" />
                </div>
              ))}
          </TinySlider> 
        </div>
      }
      { state.photos.length <= 2 && !isDesktop  &&
        <div className={styles.mobileContainer} onClick={(e) => sliderClickHandler(e)} >
           {state.photos.map((team, index) => (
                <div key={index} data-index={index} style={{ position: "relative" }} className={['team_'+team.name.toLowerCase(), sliderStyles.slide, styles.groupPhotoItem].join(' ')} >
                    <img src={'data:image/jpeg;base64,'  + team.Photo} alt="" />
                </div>
              ))}
        </div>
      }

    </div>
  );



}

export default GroupPhotos;