import React, {useState, useRef, useEffect} from 'react';
import styles from './ActionReplays.module.scss';
import TinySlider from "tiny-slider-react";
import SliderControls from "./SliderButton"
import sliderStyles from './TinySlider.module.scss';
//import { animOnSwipe } from '../scripts/animation';
import {isMobile, isIOS} from 'react-device-detect';
import ActionReplayPlaceholder from '../components/ActionReplayPlaceholder'
import { AzureMP } from 'react-azure-mp';
import { Context } from '../Store';
import ReactPlayer from 'react-player/lazy'


const ActionReplays = (props) => {
  const state = React.useContext(Context); 
  const sliderRef = useRef();

  const settings = {
    lazyload: true,
    touch: true,
    nav: false,
    controlsContainer: '.'+styles.sliderContainer + ' .sliderControls',
    mouseDrag: true,
    loop: state.actionReplays.length > 1,
    items: 1,
    responsive: {
      640:{
        items: (state.actionReplays.length < 2) ? state.actionReplays.length : 2,
        edgePadding: 30,
      },
      // 1024:{
      //   items: (state.actionReplays.length < 3) ? state.actionReplays.length : 3 ,
      //   edgePadding: 30,
      // }
    }
  };

  
  const getEventType = (eventID) => {
    switch(eventID) {
      case 5:
        return 'Last puck overtake';
      case 50:
        return 'Hanger';
      case 51:
        return 'Score5';
      case 52:
        return 'Score4';
      case 100:
        return 'ShortCircuit';
      case 150:
        return 'LateGameSteal20';
      case 151:
        return 'LateGameSteal30';
      case 152:
        return 'LateGameSteal40';
      case 153:
        return 'LateGameSteal50';
      case 154:
        return 'LateGameSteal60';
      case 155:
        return 'LateGameSteal70';
      case 200:
        return 'TotalEclipse';
      case 201:
        return 'PartialEclipse25mm';
      case 202:
        return 'PartialEclipse50mm';
      default:
        return 'Undefined';
    }
  }

  let actionReplaysVideos = state.actionReplays.map(video => {
    if(video.video_url.includes('.mp4')){
      return  <div className={styles.actionReplayItem}>
              <ReactPlayer className='react-player'
                url={video.video_url}
                muted
                controls
                playing	
                width='100%'
                height='100%'
              />
              </div>

    }else{
      return  <div className={styles.actionReplayItem}><AzureMP
          options={{controls: true, autoplay:true, muted:true}}
          src={[{src: video.video_url, type: "application/vnd.ms-sstr+xml" }]}
        />
        </div>
    }
    
   }); 
  let actionReplaysMedia = state.actionReplays.map(video => {
    return {
      mediaType: 'VIDEO',
      mediaURI: video.asset_uri,
      mediaProvider: video.video_provider,
      videoURL: video.video_url,
      sectionName: 'Action Replay',
      eventType: getEventType(video.type)
    };
  }); 

  // useEffect(() => {
  //   animOnSwipe(sliderRef.current.slider);
  // }, [sliderRef]);
  
  const handleHover = (index, target, type) => {
    let perviewDetails = {
      section: 'ActionReplay',
      index: index,
      ...actionReplaysMedia[index],
      target: target,
      groupLength: actionReplaysMedia.length,
      sources: actionReplaysVideos,
      mediashare: actionReplaysMedia
    };
    props.handleHover(perviewDetails, type)
  };

  const openLightbox = (index) => {
    let lightboxDetails = {
      section: 'ActionReplay',
      index: index, 
      sources: actionReplaysVideos,
      mediashare: actionReplaysMedia
    }

    props.openLightbox(lightboxDetails);
  }

  const sliderClickHandler = (e) => {
    let slide = e.target.closest(`.${styles.actionReplayItem}`)
    if(slide){
      openLightbox(+slide.getAttribute('data-index'))
    }
  }
  let currentHover = null;
  const sliderHoverHandler = (e) => {
    let slide = e.target.closest(`.${styles.actionReplayItem}`)
    if(slide && currentHover == null){
      currentHover = slide.getAttribute('data-index');
      handleHover(+currentHover, slide, 'mouseenter')
    }else if(!slide || e.type == 'mouseleave'){
      handleHover(+currentHover, slide, 'mouseleave')
      currentHover = null;
    }
  }

  
  let players = state.players;
  return(
    <div className="section" >
      <h2>ACTION REPLAYS</h2>

      <div className={styles.sliderContainer} onClick={(e) => sliderClickHandler(e)} onMouseLeave={isMobile ? () => (false) : (e) => sliderHoverHandler(e)} onMouseOver={isMobile ? () => (false) : (e) => sliderHoverHandler(e)} >

        <SliderControls slider={sliderRef}></SliderControls>
        
        <TinySlider settings={settings} ref={sliderRef} >
            {state.actionReplays.map((video, index) => (
              
              <div key={index} data-index={index} style={{ position: "relative" }}  className={styles.actionReplayItem}>
                { !video.thumbnail_url &&
                  <ActionReplayPlaceholder video={{video}} players={{players}} />       
                }  
                { video.thumbnail_url &&
                  <img src={video.thumbnail_url} alt="" />
                }       
              </div>
            ))}         
        </TinySlider>
    
      </div>
    </div>
  );



}

export default ActionReplays;