import React from 'react';
import ShareButton from "../ShareButton.jsx";


const EmailButton = ({mediaShareURL, shareText, mediaType, gameData, sectionName}) => (
  <ShareButton
      onClick={ () => {window.location.href = 'mailto:?body=' + shareText + ' ' + encodeURIComponent(mediaShareURL); console.log(encodeURIComponent(mediaShareURL))} }
      source="./img/share_icon_circle_email.svg"
      btnName="Email"
      mediaType={mediaType}
      gameData={gameData}
      sectionName={sectionName}
  />
);

export default EmailButton;
