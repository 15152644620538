import React, { useContext } from 'react';
import ShareButton from "../ShareButton.jsx";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CopiedContext from '../../../CopiedContext';

const LinkButton = ({mediaShareURL, shareText, mediaType, gameData, sectionName}) => {
  const { showCopied, setShowCopied } = useContext(CopiedContext);

  return(
  <CopyToClipboard text={mediaShareURL} onCopy={() => setShowCopied(true)}>
    <ShareButton
        onClick={ function(){return true} }
        source="./img/share_icon_circle_link.svg"
        btnName="Link"
        mediaType={mediaType}
        gameData={gameData}
        sectionName={sectionName}
    />
  </CopyToClipboard>
  )
};

export default LinkButton;
