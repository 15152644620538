import React from 'react';
import ShareButton from "../ShareButton.jsx";


const FacebookButton = ({mediaShareURL, shareText, mediaType, gameData, sectionName}) => (
  <ShareButton
      onClick={ () => window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(mediaShareURL) + '&quote=' + shareText) }
      source="./img/share_icon_circle_facebook.svg"
      btnName="Facebook"
      mediaType={mediaType}
      gameData={gameData}
      sectionName={sectionName}
  />
);

export default FacebookButton;
