import React from 'react';
import ShareButton from "../ShareButton.jsx";


const SMSButton = ({mediaShareURL, shareText, mediaType, gameData, sectionName}) => (
  <ShareButton
      onClick={ () => window.location.href = 'sms:?&body=' + shareText + ' ' + encodeURIComponent(mediaShareURL) }
      source="./img/share_icon_circle_sms.svg"
      btnName="SMS"
      mediaType={mediaType}
      gameData={gameData}
      sectionName={sectionName}
  />
);

export default SMSButton;
