import React, { useState, useRef, useEffect } from 'react';
import styles from './Games.module.scss';
import TinySlider from "tiny-slider-react";
import SliderControls from "./SliderButton"
import sliderStyles from './TinySlider.module.scss';
//import { animOnSwipe } from '../scripts/animation';
import {isMobile} from 'react-device-detect';
import { Context } from '../Store';


const Games = (props) => {
  const state = React.useContext(Context); 
  


  let games = state.games.map((game, index) => {
    let player = state.players.filter(player => {return player.id == game.players})[0];
    let playerPhoto = player.photo ? 'data:image/jpeg;base64, ' + player.photo : player.gender == 'male' ? './img/boy.png' : './img/girl.png' ;

    let bgColor,fgColor, message;
    switch(game.game) {
      case 'Amplifier':
        bgColor = "lightblue"
        fgColor = "darkblue"
        message = "Highest Score"
        break;
      case 'Eclipse':
        bgColor = "purple"
        fgColor = "white"
        message = "Nearest Eclipse"
        break;
      case 'Territory':
        bgColor = "orange"
        fgColor = "darkblue"
        message = "Most Territory"
        break;
      case 'ShortCircuit':
        bgColor = "darkblue"
        fgColor = "lightblue"
        message = "Shortest circuit"
        break;
      default:
        break;
    }
    return (
      // onClick={() => openLightbox(index)} onMouseEnter={isMobile ? () => (false) : handleHover.bind(this, index)} onMouseLeave={handleHover.bind(this, index)}
      <div key={game.id} id={'game_' + game.id} className={[styles.gamesItem].join(' ')} >
        <div className={[styles.gamesTile, styles[bgColor], styles['game_no-'+ state.games.length]].join(' ')}>
          <div className={styles.playerContainer}>
            <div className={styles.playerBorder}>
              <img className={styles.gamePlayer} src={playerPhoto} alt="" />
            </div> 
            <div className={styles.textContainer}>
            <div className={styles.playerName}>
              {player.name}
            </div>
            <div className={[styles.gameMessage, styles[fgColor]].join(' ')}>
                 {message}
            </div>
            </div>
            
          </div>
          
          <div className={styles.gameLogoContainer}>
            <img className={styles.gameLogo} src={"./img/game-logo-" + game.game.toLowerCase() + ".png"} alt="" />
          </div>
          
        </div>
      </div>
    )
  });

  const sliderRef = useRef();

  const settings = {
    lazyload: true,
    touch: true,
    nav: false,
    controlsContainer: '.'+styles.sliderContainer + ' .sliderControls',
    mouseDrag: true,
    loop: false,
    items: 1,
    gutter: 0,
    edgePadding: 20,
    responsive: {
      640:{
        items: (games.length < 2) ? games.length : 2 ,
        edgePadding: 30,
      },
      1024:{
        items: (games.length < 4) ? games.length : 4 ,
        edgePadding: 30,
      }
    }
  };


  // useEffect(() => {
  //   animOnSwipe(sliderRef.current.slider);
  // });

  return(
    <div className="section games_section">
      { state.teams.length > 2  &&
        <h2>TOURNAMENT HIGHLIGHTS</h2>
      }
      { state.teams.length <= 2  &&
        <h2>GAME HIGHLIGHTS</h2>
      }
 
      <div className={styles.sliderContainer}>
        <SliderControls slider={sliderRef}></SliderControls>
        
        <TinySlider settings={settings} ref={sliderRef}>
          {games}
        </TinySlider> 
      </div>
    </div>
  );



}

export default Games;