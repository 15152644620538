import React from 'react';
import styles from './ActionReplayPlaceholder.module.scss';

const ActionReplayPlaceholder = (props) => {
  let players = props.players.players;
  let video = props.video.video;
  let Game = () => {
    let player = players.filter(player => {return player.id == video.players})[0];
    let playerPhoto = player.photo ? 'data:image/jpeg;base64, ' + player.photo : player.gender == 'male' ? './img/boy.png' : './img/girl.png' ;

    const getEventType = (eventID) => {
      switch(eventID) {
        case 50:
          return 'Scores a Hanger!';
        case 51:
          return 'Scores a High\u00A05!';
        case 100:
          return 'Gets a Short Circuit!';
        case 155:
          return 'Last Puck Overtake!';
        case 200:
          return 'Gets a Total Eclipse!';
        default:
          return '';
      }
    }
    return (
      <div  className={styles.gamesItem}>
          <img className={styles.gameBackground} src="./img/ar_screen_blank.jpg" alt="" />
          <div className={styles.playerContainer}>
            <div className={styles.gamePlayer}>
              <img  src={playerPhoto} alt="" />
            </div>        
            <div className={styles.textContainer}>
              <div className={styles.playerName}>
                {player.name}   
              </div>
              <div className={styles.gameMessage}>
                  {getEventType(video.type)}
              </div>
            </div>
            
          </div>
          <div className={styles.play}>
            <img className={styles.gameBackground} src="./img/es_video-play.png" alt="" />
          </div>
          
      </div>
    )
  };

  return <Game/>;
}

export default ActionReplayPlaceholder;