import React, {useRef, useState,useEffect} from 'react';
import styles from './Players.module.scss';
import TinySlider from "tiny-slider-react";
import SliderControls from "./SliderButton"
import sliderStyles from './TinySlider.module.scss';
import { Context } from '../Store';

const Players = (props) => {
  const state = React.useContext(Context); 

  function getNumberWithOrdinal(n) {
    var s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return (s[(v - 20) % 10] || s[v] || s[0]);
  }

  const teamSliderRef = [];
  teamSliderRef[0] = useRef();
  teamSliderRef[1] = useRef();
  const playerSettings = [];
  let twoRowPlayers = false;

 for (let [i, team] of state.teams.entries()) {
  let playerCOunt = [...state.players].filter(player => {return player.team_id == team.id}).length;
  twoRowPlayers = (playerCOunt > 4) ? true : twoRowPlayers;
  playerSettings[i] = {
    lazyload: true,
    touch: true,
    nav: false,
    controlsContainer: '.'+styles.playersContainer + ' .sliderControls.multiSlider'+i,
    mouseDrag: true,
    loop: false,
    items: 2,
    gutter: 0,
    edgePadding: 0,
    responsive: {
      480:{
        items: 3,
      },
      640:{
        items: 2,
      },
      1024:{
        items: 3 ,
      },
      1248:{
        //items: 4 ,
        items: (playerCOunt < 4) ? playerCOunt : 4 ,
      }
    }
  }
 }
let tournamentSettings = {
  lazyload: true,
  touch: true,
  nav: false,
  controlsContainer: '.'+styles.playersContainer + ' .sliderControls.tournamentSliderControls',
  mouseDrag: true,
  loop: false,
  items: 1,
  gutter: 0,
  slideBy: 1,
  responsive: {
    640:{
      items: 2,
      edgePadding: 20,
    },
    1024:{
      items: 2 ,  
      edgePadding: 0,
    }
  }
}


  let teams = [];

  if(state.teams.length > 2){
    teams = [...state.teams].sort((a,b) => b.finalist - a.finalist || a.final_result - b.final_result || b.points - a.points || b.medals_gold - a.medals_gold || b.medals_silver - a.medals_silver).map((team, index) => {
      let teamColour = (team.colour == 1 ) ? 'blue' : 'pink'
      let teamPlayerArray = [...state.players].filter((player, origIndex) => {player.origIndex = origIndex; return player.team_id == team.id});
      let teamPlayers = teamPlayerArray.sort((a,b) => b.points - a.points || b.gold - a.gold || b.silver - a.silver).map((player, index) => {
        let playerPhoto = player.photo ? 'data:image/jpeg;base64, ' + player.photo : player.gender == 'male' ? './img/boy.png' : './img/girl.png';
        let teamLength = (twoRowPlayers) ? 'players_'+teamPlayerArray.length : 'onerow'
        return (
          //index === teams.length / 2 + 2? <p style={{width: '100%'}}></p> : 
          <div key={player.id} className={[styles.playerItem, styles[teamLength]].join(' ')} onClick={() => openLightbox(player.origIndex)}>
            <div className={styles.player}>
              <div className={`team_${teamColour} ${styles.playerImage}`}>
                <img src={playerPhoto} alt="" /> 
              </div>
              <div className={`team_${teamColour} ${styles.points}`}><div className={styles.pointsHeight}></div>{player.points}</div>
            </div>
            <div className={styles.playerName}>
            {player.name.toLowerCase()}
            </div>
          </div>
        )
      })
      if(teamPlayers.length > 0){
          return (
            <div  key={team.id} className={`team_${teamColour} ${styles.tournamentTeam}`}>
              <div key={index} className={styles.teamTitle}>
                <div className={`team_${team.name.toLowerCase()} ${styles.teamName}`} >{team.name.toUpperCase()}</div>
                <div className={`team_${team.name.toLowerCase()} ${styles.teamPoints}`}>{team.points}</div>
              </div>
              <div className={styles.teamPlayers}>
                  {teamPlayers}
              </div>
            </div>
          )        
      }else{
        return (false);
      }
    })

  }else{
    teams = [...state.teams].map((team, index) => {
      let teamPlayers = [...state.players].filter((player, origIndex) => {player.origIndex = origIndex; return player.team_id == team.id}).sort((a,b) => b.points - a.points || b.gold - a.gold || b.silver - a.silver).map((player, index) => {
        let playerPhoto = player.photo ? 'data:image/jpeg;base64, ' + player.photo : player.gender == 'male' ? './img/boy.png' : './img/girl.png';
        let teamColour = (['pink', 'blue'].includes(team.name.toLowerCase())) ? team.name.toLowerCase() : (team.colour == 1 ) ? 'blue' : 'pink';
        return (
          <div key={player.id} className={styles.playerItem} onClick={() => openLightbox(player.origIndex)}>
            <div className={styles.player}>
              <div className={`team_${teamColour} ${styles.playerImage}`}>
                <img src={playerPhoto} alt="" /> 
              </div>
              <div className={`team_${teamColour} ${styles.points}`}><div className={styles.pointsHeight}></div>{player.points}</div>
            </div>
            <div className={styles.playerName}>
            {player.name.toLowerCase()}
            </div>
          </div>
        )
      })
      if(teamPlayers.length > 0){
          return (
            // <div  key={team.id} className={styles.teamItem}>
              <div key={team.id} className={styles.playerSlider}>
                <SliderControls slider={teamSliderRef[index]} additionalClass={'multiSlider'+index}></SliderControls>
                <TinySlider settings={playerSettings[index]} ref={teamSliderRef[index]}>
                  {teamPlayers}
                </TinySlider>
              </div>
            // </div>
          ) 
      }else{
        return (false);
      }
    })
  }

 


  let TeamPoints = () => {
    let points = [];
    state.teams.map((team, index) => {
      let teamColour = (['pink', 'blue'].includes(team.name.toLowerCase())) ? team.name.toLowerCase() : (team.colour == 1 ) ? 'blue' : 'pink';
      points.push(
        <div key={index} className={styles.teamTitle}>
          <div className={`team_${teamColour} ${styles.teamName}`} >{team.name.toUpperCase()}</div>
          <div className={`team_${teamColour} ${styles.teamPoints}`}>{team.points}</div>
        </div>
      )
    })
    return points;
  }

  const PlayerView = () => {
    let playerView;
    if(state.teams.length > 2){
      playerView = 
      <div className={styles.tournamentSlider}>
        <SliderControls slider={teamSliderRef[0]} additionalClass={'tournamentSliderControls'}></SliderControls>
        <TinySlider settings={tournamentSettings} ref={teamSliderRef[0]}>
          {teams}
        </TinySlider>
      </div>
      
    }else{
      playerView = 
      <div className={styles.playerSliderContainer}>
        {teams}
      </div> ;
    }
    return playerView;

  }

  let playerPhotoSources = state.players.map(player => {
    return  'data:image/jpeg;base64,'  + player.photo;
  }); 
  let playerPhotoMedia = state.players.map(player => {
    return {
      mediaType: 'PHOTO',
      mediaShare: state.guid +  '&player=' + player.id,
      mediaURI: 'data:image/jpeg;base64,'  + player.photo,
      sectionName: 'Player Photo'
    };
  });

  const openLightbox = (index) => {
    let lightboxDetails = {
      section: 'Players',
      index: index, 
      sources: playerPhotoSources,
      mediashare: playerPhotoMedia,
      type:"image"
    }
    props.openLightbox(lightboxDetails);
  }


  //let playerView = teams ;
  let stylesSection = (state.teams.length < 3) ? 'teams_section player_slider_section' : 'teams_section'  ;

  return (
    <div className={`section ${stylesSection}`}>
      { state.teams.length > 2  &&
        <h2>TEAMS</h2>
      }
      <div className="">
        { state.teams.length < 3  &&
          <div className={styles.pointsContainer}>
            <TeamPoints/>
          </div>
        }
        <div className={styles.playersContainer}>
          <PlayerView/>
        </div>
      </div>
    </div>
  )
}

export default Players;